.container {
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0px 4px;
  border-radius: 6px;
}

.mythic {
  background-color: #f786aa;
}

.legendary {
  background-color: #ee964b;
}

.rare {
  background-color: #6290c3;
}

.epic {
  background-color: #8d6a9f;
}

.uncommon {
  background-color: #96e6b3;
}

.common {
  background-color: #a1a6b4;
}
