.rarityBaseContainer {
  border-radius: 22px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #eff9f0;
  align-items: center;
}

.rarityBaseContainer:hover {
  cursor: pointer;
}
