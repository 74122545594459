.featuredMainContainer {
  height: 65vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  justify-content: center;
}

.backgroundImage {
  opacity: 0.7;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  -moz-box-shadow: 0 0 20px 8px 10px #050609 inset;
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 75px 75px #050609;
}

.mainContent {
  position: relative;
  padding-left: 8%;
  /* z-index: 0; */
}

.exploreButton {
  cursor: pointer;
}

.exploreButton:hover {
  background-color: #f1779a;
}
