.exploreButton {
  justify-content: center;
  width: 120px;
  height: 40px;
  border-style: none;
  border: 1px solid #2e282a;
  border-radius: 22px;
  background-color: transparent;
  cursor: pointer;
}
