.baseContainer {
  border-radius: 22px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #4165b4;
  background-color: #eff9f0;
}

.exploreButton {
  justify-content: center;
  width: 120px;
  height: 40px;
  border-style: none;
  border: 1px solid #2e282a;
  border-radius: 22px;
  background-color: transparent;
  cursor: pointer;
}

.image {
  width: 100%;
}
