.mintButton {
  height: 50px;
  width: 100px;
  background-color: #f1779a;
  border-style: none;

  border-radius: 12px;
  cursor: pointer;
}

.mintButton:active {
  background-color: #ffb4ac;
}
