.rarityBaseContainer {
  border-radius: 22px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #eff9f0;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 40px;
  justify-content: center;
  width: 100%;
  /* align-items: space-between; */
}

.searchBar {
  border-radius: 20px;
  padding-left: 20px;
  font-size: 14px;
  min-width: 250px;
  margin: auto;
  height: 40px;
  border-style: none;
}

.searchButton {
  background-color: #f1779a;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

:hover .searchButton {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}

:hover .disabled {
  cursor: auto;
}
