.paginationContainer {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.paginationContainer .paginationItem {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: #f0eff4;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.paginationContainer .paginationItem.dots:hover {
  background-color: transparent;
  cursor: default;
}
.paginationContainer .paginationItem:hover {
  background-color: rgba(255, 255, 255, 0.103);
  cursor: pointer;
}
.paginationContainer .paginationItem.selected {
  background-color: #f1779a;
}
.paginationContainer .paginationItem .arrow::before {
  position: relative;
  /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid #f0eff4;
  border-top: 0.12em solid #f0eff4;
}
.paginationContainer .paginationItem .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.paginationContainer .paginationItem .arrow.right {
  transform: rotate(45deg);
}
.paginationContainer .paginationItem.disabled {
  pointer-events: none;
}
.paginationContainer .paginationItem.disabled .arrow::before {
  border-right: 0.12em solid black;
  border-top: 0.12em solid black;
}
.paginationContainer .paginationItem.disabled:hover {
  background-color: transparent;
  cursor: default;
}
